import React from 'react'
import { Main } from '../Components/Checkout/Main'

export const Checkout = () => {
  return (
    <>
    <Main/>
    </>
  )
}
