
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { MdOutlineCancel } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


export const Main = () => {
  const [cartProducts, setCartProducts] = useState([]);
  console.log('cartProducts', cartProducts);
  
  const [totallAmount, setTotallAmount] = useState([]);
  console.log('all data', totallAmount);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fetchingShippingCost, setFetchingShippingCost] = useState(true);
  const [shippingCost, setShippingCost] = useState(null); // Initially null for loader
  const [serviceCode, setServiceCode] = useState("");
  const userId = sessionStorage.getItem("userId") || sessionStorage.getItem("randomUserId");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    address: "",
    zip: "",
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDg6Ci3L6yS5YvtKAkWQjnodGUtlNYHw9Y&libraries=places`;
    script.async = true;
    script.onload = () => initAutocomplete();
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initAutocomplete = () => {
    const input = document.getElementById("address-input");
    const autocomplete = new window.google.maps.places.Autocomplete(input);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.address_components) {
        setFormData((prevData) => ({
          ...prevData,
          address: place.formatted_address,
          city: getComponentValue(place.address_components, "locality"),
          state: getComponentValue(place.address_components, "administrative_area_level_1"),
          country: getComponentValue(place.address_components, "country"),
          shortName: getComponentValue(place.address_components, "country", true),
          zip: getComponentValue(place.address_components, "postal_code"),
        }));
        // Update shipping cost whenever the location details are updated
        fetchShippingCost(getComponentValue(place.address_components, "country", true), getComponentValue(place.address_components, "postal_code"));
      }
    });
  };

  const getComponentValue = (components, type, useShortName = false) => {
    const component = components.find((c) => c.types.includes(type));
    return component ? (useShortName ? component.short_name : component.long_name) : "";
  };




  const fetchShippingCost = async (countryCode, postalCode) => {
    setFetchingShippingCost(true); // Show loader while fetching
    try {
      const response = await axios.get(
        `https://ecom.testingwebsitelink.com/api/testingShipping?to_country=${countryCode}&to_postal_code=${postalCode}&weight=${totalWeight}`
      );
      const shippingAmount = response.data.data[0]?.shipping_amount.amount;
      const serviceCode = response.data.data[0].service_code;
      setShippingCost(shippingAmount || 0); // Set shipping cost
      setServiceCode(serviceCode); 
      setFetchingShippingCost(false); // Hide loader after data is set
    } catch (error) {
      console.error("Error fetching shipping cost:", error);
      setShippingCost(0);
      setFetchingShippingCost(false);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await axios.post(
        "https://ecom.testingwebsitelink.com/api/checkout",
        {
          ...formData,
          user_id: userId,
          amount: totallAmount.total,
          shipping_cost: shippingCost,
          service_code: serviceCode,
        }
      );
      toast.success("Check Your Invoice for Payment successful");
      setShowModal(true);
    } catch (error) {
      console.error("Error making payment:", error);
      toast.error("Payment failed");
    }

    setLoading(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const navigation = useNavigate();
  const incNum = (index, variation) => {
    setCartProducts((prevProducts) => {
      if (!Array.isArray(prevProducts)) return prevProducts;
      const updatedProducts = [...prevProducts];
      const productIndex = updatedProducts.findIndex(
        (p) => p.product_id === variation.product_id
      );
      const variationIndex = updatedProducts[
        productIndex
      ].product_variations.findIndex(
        (v) => v.variation_id === variation.variation_id
      );
      const currentQuantity =
        parseInt(
          updatedProducts[productIndex].product_variations[variationIndex]
            .quantity,
          10
        ) || 0;
      updatedProducts[productIndex].product_variations[
        variationIndex
      ].quantity = currentQuantity + 1;
      handleCartUpdate(
        updatedProducts[productIndex].product_variations[variationIndex]
      );
  
      // Fetch updated shipping cost based on current address details
      fetchShippingCost(formData.country, formData.zip); // Using formData for location
  
      return updatedProducts;
    });
  };
  
  const decNum = (index, variation) => {
    setCartProducts((prevProducts) => {
      if (!Array.isArray(prevProducts)) return prevProducts;
      const updatedProducts = [...prevProducts];
      const productIndex = updatedProducts.findIndex(
        (p) => p.product_id === variation.product_id
      );
      const variationIndex = updatedProducts[
        productIndex
      ].product_variations.findIndex(
        (v) => v.variation_id === variation.variation_id
      );
      const currentQuantity =
        parseInt(
          updatedProducts[productIndex].product_variations[variationIndex]
            .quantity,
          10
        ) || 0;
      if (currentQuantity > 1) {
        updatedProducts[productIndex].product_variations[
          variationIndex
        ].quantity = currentQuantity - 1;
        handleCartUpdate(
          updatedProducts[productIndex].product_variations[variationIndex]
        );
      }
  
      // Fetch updated shipping cost based on current address details
      fetchShippingCost(formData.country, formData.zip); // Using formData for location
  
      return updatedProducts;
    });
  };
  

  const handleChange = (e, variation) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (isNaN(newQuantity) || newQuantity < 0) return;
    setCartProducts((prevProducts) => {
      if (!Array.isArray(prevProducts)) return prevProducts;
      const updatedProducts = [...prevProducts];
      const productIndex = updatedProducts.findIndex(
        (p) => p.product_id === variation.product_id
      );
      const variationIndex = updatedProducts[
        productIndex
      ].product_variations.findIndex(
        (v) => v.variation_id === variation.variation_id
      );
      updatedProducts[productIndex].product_variations[
        variationIndex
      ].quantity = newQuantity;
      handleCartUpdate(
        updatedProducts[productIndex].product_variations[variationIndex]
      );
      return updatedProducts;
    });
  };

  useEffect(() => {
    const userId =
      sessionStorage.getItem("userId") ||
      sessionStorage.getItem("randomUserId");
    const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";

    if (!userId || !token) {
      console.error("User ID or token not found");
      return;
    }

    const fetchCartProducts = async () => {
      try {
        const response = await axios.get(
          `https://ecom.testingwebsitelink.com/api/getcart?user_id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setCartProducts(response.data.products || []); // Ensure it's an array
        setTotallAmount(response.data);
        setIsLoading(false);

        console.log("Cart Products:", response.data.products);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching cart products:", error);
      }
    };

    fetchCartProducts();
  }, []);

  const totalWeight = cartProducts.reduce((sum, product) => sum + (product.total_weight || 0), 0);

  const handleCartRemove = async (variation) => {
    const userId =
      sessionStorage.getItem("userId") ||
      sessionStorage.getItem("randomUserId");
    const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk"; // Replace with your API token

    try {
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/removefromcart",
        {
          user_id: userId,
          product_id: variation.product_id,
          variation_id: variation.variation_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Product Removed successfully:", response.data);
      // toast.success("Product Removed successfully");
      window.location.reload();

      setCartProducts((prevProducts) => {
        if (!Array.isArray(prevProducts)) return prevProducts;
        return prevProducts.filter(
          (product) =>
            product.product_id !== variation.product_id ||
            product.variation_id !== variation.variation_id
        );
      });
    } catch (error) {
      console.error("Error removing Product:", error);
      toast.error("Error removing Product");
    }
  };

  const handleCartUpdate = async (variation) => {
    const userId =
      sessionStorage.getItem("userId") ||
      sessionStorage.getItem("randomUserId");
    const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk"; // Replace with your API token

    try {
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/updatecart",
        {
          user_id: userId,
          product_id: variation.product_id,
          variation_id: variation.variation_id,
          quantity: variation.quantity,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Cart Updated successfully:", response.data);
      toast.success("Cart Updated successfully");
    } catch (error) {
      console.error("Error updating Cart:", error);
      toast.error("Error updating Cart");
    }
  };
  const clearCart = async () => {
    const userId =
      sessionStorage.getItem("userId") ||
      sessionStorage.getItem("randomUserId");
    const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk"; // Replace with your API token

    try {
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/clearcart",
        {
          user_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Variation Updated successfully:", response.data);
      // toast.success("Variation Updated successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error updating variation:", error);
      toast.error("Error Removing Cart");
    }
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <>
      <section className="checkoutSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              {cartProducts.length === 0 ? (
                <div></div>
              ) : (
                <div className="checkoutCol">
                  <h2>Contact Details</h2>
                  <button onClick={() => clearCart()} class="proceedCheckout3">
                    Clear Cart
                  </button>
                  <div className="formCheckout rendering">
                    <h2>Shipping details</h2>

                    <Form onSubmit={handleSubmit}>
       <h3>
          
          {fetchingShippingCost ? (
            <Spinner style={{display:'none'}} animation="border" size="sm" />
          ) : (
            <>
            <Form.Control
                type="hidden"
                name="fname"
                value={`${shippingCost}`}
                onChange={ (e) => {
                  console.log(e.target.value);
                  setShippingCost(e.target.value);
                }}
                placeholder="Shipping :"
                required
              />
            <Form.Control
                type="hidden"
                name="fname"
                value={`${serviceCode}`}
                onChange={(e) => {
                  console.log(e.target.value);
                  setServiceCode(e.target.value);
                }}
                placeholder="Shipping :"
                required
              />
              </>
          )}
        </h3>
        <Row>
          <Col sm="6" lg="6" xl="6">
            <Form.Group className="mb-3" controlId="formBasicFirst">
              <Form.Control
                type="text"
                name="fname"
                value={formData.fname}
                onChange={(e) => {
                  console.log(e.target.value);
                  setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
                }}
                placeholder="First Name :"
                required
              />
            </Form.Group>
          </Col>
          <Col sm="6" lg="6" xl="6">
            <Form.Group className="mb-3" controlId="formBasicLast">
              <Form.Control
                type="text"
                name="lname"
                value={formData.lname}
                onChange={(e) => {console.log(e.target.value);
                  setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
                }}
                placeholder="Last Name :"
                required
              />
            </Form.Group>
          </Col>
          <Col sm="6" lg="6" xl="6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={(e) => {console.log(e.target.value);
                  setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
                }}
                placeholder="Email :"
                required
              />
            </Form.Group>
          </Col>
          <Col sm="6" lg="6" xl="6">
            <Form.Group className="mb-3" controlId="formBasicPhone">
              <Form.Control
                type="number"
                name="phone"
                value={formData.phone}
                onChange={(e) => {console.log(e.target.value);
                  setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
                }}
                placeholder="Phone :"
                required
              />
            </Form.Group>
          </Col>
          <Col sm="6" lg="6" xl="6">
            <Form.Group className="mb-3" controlId="formBasicAddress">
            <Form.Control
  type="text"
  id="address-input" // Use this ID for the Google Places Autocomplete
  name="address"
  value={formData.address}
 onChange={(e) => setFormData({...formData, address: e.target.value})}
  placeholder="Address"
  required
  onKeyDown={(e) => {
    // Prevent form submission when pressing Enter on address input
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }}
/>
            </Form.Group>
          </Col>
          <Col sm="6" lg="6" xl="6">
            <Form.Group className="mb-3" controlId="formBasicCountry">
              <Form.Control
                type="text"
                name="country"
                value={formData.country}
                onChange={(e) => {console.log(e.target.value);
                  setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
                }}
                placeholder="Country"
                required
              />
            </Form.Group>
          </Col>
          
            {/* <Form.Group className="mb-3" controlId="formBasicCountry">
              <Form.Control
                type="hidden"
                name="shortname"
                value={formData.shortName}
                onChange={(e) => {console.log(e.target.value);
                placeholder="shortname"
                required
              />
            </Form.Group> */}
          
          <Col sm="4" lg="4" xl="4">
            <Form.Group className="mb-4" controlId="formBasicState">
              <Form.Control
                type="text"
                name="state"
                value={formData.state}
                onChange={(e) => {console.log(e.target.value);
                  setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
                }}
                placeholder="State"
                required
              />
            </Form.Group>
          </Col>
          <Col sm="4" lg="4" xl="4">
            <Form.Group className="mb-4" controlId="formBasicCity">
              <Form.Control
                type="text"
                name="city"
                value={formData.city}
                onChange={(e) => {console.log(e.target.value);
                  setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
                }}

                placeholder="Town/City :"
                required
              />
            </Form.Group>
          </Col>
          <Col sm="4" lg="4" xl="4">
            <Form.Group className="mb-3" controlId="formBasicZip">
              <Form.Control
                type="text"
                name="zip"
                value={formData.zip}
                onChange={(e) => {console.log(e.target.value);
                  setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
                }}
                placeholder="Zip Code :"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <button className="proceedCheckout3 mt-2" type="submit">
          {loading ? "Loading..." : "Pay Now"}
        </button>
      </Form>
      {/* Bootstrap Modal for order confirmation */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>Order Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Order placed successfully. Please check your invoice for payment.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Continue Shopping
          </Button>
        </Modal.Footer>
      </Modal>
                    {/* <Elements stripe={stripePromise}>
                    <PaymentForm totallAmount={totallAmount} totalWeight={totalWeight} />
                  </Elements> */}
                  </div>
                </div>
              )}
              <div className="checkoutCol1">
                <div className="formCheckout1">
                  <h2>Your Cart</h2>
                  <div className="cartTable">
                    {cartProducts.length === 0 ? (
                      <div className="text-white pt-4">
                        <div
                          class="alert alert-warning alert-dismissible fade show text-capitalize"
                          role="alert"
                        >
                          <strong>No cart available</strong>
                        </div>
                      </div>
                    ) : (
                      <div className="cartHeader">
                        <div className="headerItem">Coffee</div>
                        <div className="headerItem">Quantity</div>
                        <div className="headerItem">Price</div>
                      </div>
                    )}
                    {isLoading ? (
                      <div className="loader-container text-center">
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                    ) : (
                      <>
                        {cartProducts.length === 0 ? (
                          <div></div>
                        ) : (
                          cartProducts.map((product) => (
                            <div className="cartItem" key={product.id}>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div className="productInfo">
                                  {product.product_images &&
                                    product.product_images.length > 0 && (
                                      <img
                                        className="productImage"
                                        src={
                                          product.product_images[0]
                                            .feature_image
                                        }
                                        alt="Product"
                                      />
                                    )}
                                  <div className="productDetails">
                                    <h5>{product.product_name}</h5>
                                  </div>
                                </div>
                              </div>
                              <div className="row justify-content-center">
                                <div className="col-md-12">
                                  <div className="table-responsive">
                                    <Table id="myTable" className="table">
                                      <tbody>
                                        {product.product_variations &&
                                          product.product_variations.length >
                                            0 &&
                                          product.product_variations.map(
                                            (variation, index) => (
                                              <tr key={index}>
                                                <td>
                                                  <div className="product-img">
                                                    <div className="img-prdct">
                                                      <img
                                                        src={variation.image}
                                                        alt={
                                                          variation.variation_name
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <p>
                                                    {variation.variation_name}
                                                  </p>
                                                </td>
                                                <td>
                                                  <div className="button-container">
                                                    <button
                                                      className="cart-qty-minus"
                                                      type="button"
                                                      value="-"
                                                      onClick={() =>
                                                        decNum(index, variation)
                                                      }
                                                    >
                                                      -
                                                    </button>
                                                    <input
                                                      type="text"
                                                      name="qty"
                                                      min="1"
                                                      readOnly
                                                      className="qty form-control"
                                                      value={
                                                        variation.quantity || 0
                                                      }
                                                      onChange={(e) =>
                                                        handleChange(
                                                          e,
                                                          variation
                                                        )
                                                      }
                                                    />
                                                    <button
                                                      className="cart-qty-plus"
                                                      type="button"
                                                      value="+"
                                                      onClick={() =>
                                                        incNum(index, variation)
                                                      }
                                                    >
                                                      +
                                                    </button>
                                                  </div>
                                                </td>
                                                <td
                                                  align="right"
                                                  style={{ display: "flex" }}
                                                >
                                                  ${" "}
                                                  <span
                                                    id="amount"
                                                    className="amount"
                                                  >
                                                    {
                                                      variation.variation_total_price
                                                    }
                                                  </span>
                                                </td>
                                                <td>
                                                  <MdOutlineCancel
                                                    onClick={() =>
                                                      handleCartRemove(
                                                        variation
                                                      )
                                                    }
                                                    className="cancel"
                                                  />
                                                </td>
                                              </tr>
                                            )
                                          )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {cartProducts.length === 0 ? (
              <div></div>
            ) : (
              <div className="col-lg-5">
                <div className="checkoutCol2 checkoutCol1">
                  <div className="formCheckout2">
                    <h2>ORDER DETAILS</h2>
                    <div className="cartTable">
                      {isLoading ? (
                        <div className="loader-container text-center">
                          <Spinner animation="border" role="status" />
                        </div>
                      ) : (
                        <>
                          {cartProducts.length === 0 ? (
                            <div className="text-white pt-4">
                              No cart available
                            </div>
                          ) : (
                            cartProducts.map((product) => (
                              <div className="cartItem" key={product.id}>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="productInfo">
                                    {product.product_images &&
                                      product.product_images.length > 0 && (
                                        <img
                                          className="productImage"
                                          src={
                                            product.product_images[0]
                                              .feature_image
                                          }
                                          alt="Product"
                                        />
                                      )}
                                    <div className="productDetails">
                                      <h5>{product.product_name}</h5>
                                    </div>
                                  </div>
                                </div>
                                <div className="row justify-content-center">
                                  <div className="col-md-12">
                                    <div className="table-responsive">
                                      <Table id="myTable" className="table">
                                        <tbody>
                                          {product.product_variations &&
                                            product.product_variations.length >
                                              0 &&
                                            product.product_variations.map(
                                              (variation, index) => (
                                                <tr key={index}>
                                                  <td>
                                                    <div className="product-img">
                                                      <div className="img-prdct">
                                                        <img
                                                          src={variation.image}
                                                          alt={
                                                            variation.variation_name
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <p>
                                                      {variation.variation_name}
                                                    </p>
                                                  </td>
                                                  <td>
                                                    <div className="button-container">
                                                      <button
                                                        className="cart-qty-minus"
                                                        type="button"
                                                        value="-"
                                                        onClick={() =>
                                                          decNum(
                                                            index,
                                                            variation
                                                          )
                                                        }
                                                      >
                                                        -
                                                      </button>

                                                      <input
                                                        type="number"
                                                        name="qty"
                                                        min="1"
                                                        readOnly
                                                        className="qty form-control"
                                                        value={
                                                          variation.quantity ||
                                                          0
                                                        }
                                                        onChange={(e) =>
                                                          handleChange(
                                                            e,
                                                            variation
                                                          )
                                                        }
                                                      />
                                                      <button
                                                        className="cart-qty-plus"
                                                        type="button"
                                                        onClick={() =>
                                                          incNum(
                                                            index,
                                                            variation
                                                          )
                                                        }
                                                      >
                                                        +
                                                      </button>
                                                    </div>
                                                  </td>
                                                  <td
                                                    align="right"
                                                    style={{ display: "flex" }}
                                                  >
                                                    ${" "}
                                                    <span
                                                      id="amount"
                                                      className="amount"
                                                    >
                                                      {
                                                        variation.variation_total_price
                                                      }
                                                    </span>
                                                  </td>
                                                  <td>
                                                    <MdOutlineCancel
                                                      onClick={() =>
                                                        handleCartRemove(
                                                          variation
                                                        )
                                                      }
                                                      className="cancel"
                                                    />
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </>
                      )}
                    </div>
                    <div className="cartDetails">
                      <h2>Have a coupon? Click here to enter your code</h2>
                      <hr />

                      <div className="detailsOfProduct">
                        <h2>Shipping</h2>
                        <h2> {fetchingShippingCost ? (
                          <span>---</span>
          ) : (
            <>
            ${shippingCost }
              </>
          )}</h2>
                      </div>
                      <hr />
                      <div className="detailsOfProduct">
                        <h2>Total</h2>
                        <h2>
                          ${" "}
                          {totallAmount.total == null ? (
                            <>---</>
                          ) : (
                            <>{totallAmount.total}</>
                          )}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="brandLogo">
                  <img
                    src={require("../assets/images/Coffeebrand1.png")}
                    alt=""
                  />
                  <img
                    src={require("../assets/images/Coffeebrand2.png")}
                    alt=""
                  />
                  <img
                    src={require("../assets/images/Coffeebrand3.png")}
                    alt=""
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
