import React from 'react'
import { Banner } from '../Components/HomeComponent/Banner'
import { SpecialOnlineShop } from '../Components/HomeComponent/SpecialOnlineShop'

export const Home = () => {
  return (
    <>
    <Banner />
    <SpecialOnlineShop />
    </>
  )
}
