import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Topbar } from "./Components/HeaerAndFooter/Topbar";
import { Home } from "./Screens/Home";
import { Footer } from "./Components/HeaerAndFooter/Footer";
import { Coffee } from "./Screens/Coffee";
import { Subscription } from "./Screens/Subscription";
import { Aboutus } from "./Screens/Aboutus";
import { Contactus } from "./Screens/Contactus";
import { Supporting } from "./Screens/Supporting";
import { Checkout } from "./Screens/Checkout";
import { Payment } from "./Screens/Payment";
import Login from "./Screens/Login";
import Register from "./Screens/Register";
import { ToastContainer } from "react-toastify";
import { Draft } from "./Screens/Draft";
import { AllDraft } from "./Screens/AllDraft";
import { CartPayment } from "./Screens/CartPayment";
import useScrollRestoration from "./Components/ScrollComp/useScrollRestoration";
import ScrollToTop from "./Components/ScrollComp/ScrollToTop";
import ChatSupport from "./Components/HeaerAndFooter/ChatSupport";
import ChatBot from "./Components/HeaerAndFooter/ChatBot";

function App() {
  useScrollRestoration(); // Use the custom hook
  return (
    <div className="App bg-banner">
      <ToastContainer
				position="top-right"
				autoClose={2000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
      <Topbar />
      <ScrollToTop/>
      <Routes>
        <>
          <Route path="/" element={<Home />} />
          <Route path="/coffee/:id" element={<Coffee />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/draft/:id" element={<Draft />} />
          <Route path="/all-draft/:id" element={<AllDraft />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/supporting-veterans" element={<Supporting />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/payment/:id" element={<Payment />} />
          <Route path="/cart-payment/:id" element={<CartPayment />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </>
      </Routes>
      <ChatSupport/>
      {/* <ChatBot/> */}
      <Footer />
    </div>
  );
}

export default App;
