// import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <>
      <section className="footerSection">
        <div className="container">
          <div className="forborderFooter full-withradius">
            <div className="row">
            <div className="col-lg-3 col-6 veteranClass">
                <img src={require("../assets/logo/veteran-founded.png")} alt="veteran founded" />
              </div>
              {/* <div className="col-lg-4"></div> */}
              <div className="col-lg-5 md-col-6 py-4">
                <h2 className="text-center h2footer">
                  {/* <span>Coffee Build</span> <br />
                  Your Fresh mind */}
                  <span>Fresh Coffee</span> <br />
                </h2>
                <div className="subscribe">
                  <input type="text" placeholder="Enter Your Email" />
                  <button>Subscribe</button>
                </div>
              </div>
              <div className="col-lg-3 col-6 flagClass">
                <img src={require("../assets/images/flag.png")} alt="flag" />
              </div>
              <div className="col-lg-4 ">
                <div className="footerLogo">
                  <img
                    width="250px"
                    src={require("../assets/logo/logo.png")}
                    alt="logo"
                  />
                   <div className="callToAction mt-4">
                  <div className="socialMediaLink">
                    <FaFacebook className="socialMediaIcon" />
                  </div>
                  <div className="socialMediaLink">
                    <FaInstagram className="socialMediaIcon" />
                  </div>
                  <div className="socialMediaLink">
                    <FaLinkedin className="socialMediaIcon" />
                  </div>
                  <div className="socialMediaLink">
                    <FaXTwitter className="socialMediaIcon" />
                  </div>
                </div>
                  {/* <p className="mt-2">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500sLorem Ipsum is
                    simply dummy
                  </p> */}
                </div>
              </div>
              {/* <div className="col-lg-2"></div> */}
              <div className="col-lg-5 col-md-4 footerContent">
                <div className="footerMenu">
                  <h4 className="cont">Quick Links</h4>
                  <ul>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/supporting-veterans">Supporting Veterans</Link>
                    </li>
                    <li>
                      <a href="#">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 footerContent">
                <div className="footerMenu">
                  <h4 className="cont">Support</h4>
                  <ul>
                    <li>
                      <Link to="/contact-us">Contact</Link>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#">Terms & Conditions</a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="col-lg-3">
               
              </div> */}
             
              {/* <div className="col">
                <div className="yellowBottom"></div>
              </div> */}
            </div>
          </div>
          {/* <div class="full-withradius border">Gradient border with border radius</div> */}
        </div>
        <div className="container-fluid lastFoot">
            <div className="row">
            <div className="col-lg-12 copyrightText">
                <div className="copyrightText">
                  <h6>
                    Copyright 2024 &copy;
                    <span> First Call Coffe Co. </span>
                    All rights reserved.
                  </h6>
                </div>
              </div>
              </div>
              </div>
      </section>
    </>
  );
};
