import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaArrowRightLong } from "react-icons/fa6";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

export const SpecialOnlineShop = () => {
  const [products, setProducts] = useState([]);
  console.log("data", products);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get token from local storage
        const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";

        // Make API request with token
        const response = await axios.get(
          "https://ecom.testingwebsitelink.com/api/getallproducts",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("databymmm", response.data);
        // Set the fetched data to the state
        setProducts(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <section className="special-online-shop">
        <div className="container SpecialOnlineShop">
          <div className="row align-items-center">
            <div className="col-md-9 col-6 subtitle">
              {/* <p>SPECIAL ONLINE SHOP</p> */}
              <h2>
                <span>Our Products</span>
              </h2>
            </div>
            {/* <div className="col-md-3 col-6 view-all">
              <a href="#">See All Products</a>
            </div> */}
          </div>
          <div className="row products">
            {products.map((item, index) => (
              <div className="col-md-4 col-6" key={index}>
                <Link to={`/coffee/${item.id}`}>
                  <div className="special-online-shop-box">
                  <div className="img-box">
                    <img src={item.feature_image} alt="special-online-shop" />
                    </div>
                    <div className="subtitleBox">
                      <h4>{item.title}</h4>
                      {/* <HiOutlineArrowNarrowRight /> */}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
