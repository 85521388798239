import React from 'react'

export const Brands = () => {
  return (
    <>
    <div className="container branding mb-4">
        <div className="row" style={{justifyContent:'center', gap:'17px 0px'}}>
            <div className='col-lg-4 col-md-4 col-6'>
                <img style={{objectFit:'contain',width:'100%'}} src={require('../assets/images/brand1.png')} alt=''/>
            </div>
            <div className='col-lg-4 col-md-4 col-6'>
                <img style={{objectFit:'contain',width:'100%'}} src={require('../assets/images/brand2.jpg')} alt=''/>
            </div>
            <div className='col-lg-4 col-md-4 col-6'>
                <img style={{objectFit:'contain',width:'100%'}} src={require('../assets/images/brand3.jpg')} alt=''/>
            </div>
        </div>
    </div>
    </>
  )
}
