import React from 'react'
import { Main } from '../Components/DraftComp/Main'

export const Draft = () => {
  return (
    <>
    <Main/>
    </>
  )
}
