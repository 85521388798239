import React from 'react'
import { Main } from '../Components/PaymentCoponent/Main'

export const Payment = () => {
  return (
    <>
    <Main/>
    </>
  )
}
