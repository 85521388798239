import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import Bootstrap CSS

const Main = () => {
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        last_name: "",
        password: ""
    });
    const [errorMessage, setErrorMessage] = useState("");

    const handleShow = (message) => {
        setErrorMessage(message);
        setShowPopup(true);
    };
    const handleClose = () => setShowPopup(false);

    const handleRegistration = async (event) => {
        event.preventDefault();
        try {
            const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";
            const response = await axios.post(
                "https://ecom.testingwebsitelink.com/api/customer/register", // Updated endpoint to "register"
                {
                    email: formData.email,
                    password: formData.password,
                    name: formData.name,
                    last_name: formData.last_name
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log("Registration response:", response.data);
            navigate("/login");
            window.location.reload();
        } catch (error) {
            console.error("Error registering user:", error);
            handleShow("Email already exists or another error occurred."); // Update message as needed
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
                <form onSubmit={handleRegistration}>
                        <h3 className="Auth-form-title">SIGN UP</h3>
                    <div className="Auth-form-content">
                    <div className="form-group mt-3">
                            <label>First Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="form-control mt-1"
                                placeholder="e.g Jane"
                                required
                            />
                        </div>
                    <div className="form-group mt-3">
                            <label>Last Name</label>
                            <input
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                className="form-control mt-1"
                                placeholder="e.g Doe"
                                required
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Email Address</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="form-control mt-1"
                                placeholder="Enter email"
                                required
                            />
                        </div>
                        
                        {/* <div className="form-group mt-3">
                            <label>Phone Number</label>
                            <input
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className="form-control mt-1"
                                placeholder="e.g +99 999 000"
                                required
                            />
                        </div> */}
                        <div className="form-group mt-3">
                            <label>Password</label>
                            <input
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="form-control mt-1"
                                placeholder="Enter password"
                                required
                            />
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-color-login">
                                CREATE ACCOUNT
                            </button>
                        </div>
                        {/* <p className="text-center mt-2">
                            Forgot <a href="#">password?</a>
                        </p> */}
                        <div className="text-center">
                            <Link className="login-link-color" to="/">
                             RETURN TO STORE
                            </Link>
                        </div>
                    </div>
                </form>
                <Modal show={showPopup} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                </Modal>
        </>
    );
};

export default Main;
