import React, { useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import Bootstrap CSS
import { Tab, Tabs } from "react-bootstrap";
import Main from "../RegisterComponent/Main";

const Login = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };
  const handleClose = () => setShowPopup(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/customer/login",
        {
          email: formData.email,
          password: formData.password
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Login response:", response.data);
      console.log("userId:", response.data.data.id);
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("randomUserId");
      // Save username or ID in session storage
      sessionStorage.setItem("userId", response.data.data.id);
      sessionStorage.setItem("userToken", response.data.data.token);
      // Redirect to home page
      navigate("/");
      window.location.reload();

    } catch (error) {
      console.error("Error logging in:", error);
      handleShow("Invalid email or password. Please try again."); // Update message as needed
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
     <div className="Auth-form-container">
    <div className="Auth-form">
    <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Register">
        <Main/>
      </Tab>
      <Tab eventKey="profile" title="Login">
     
       
       <form onSubmit={handleLogin}>
           <h3 className="Auth-form-title">SIGN IN</h3>
         <div className="Auth-form-content">
           <div className="form-group mt-3">
             <label>Email address</label>
             <input
               type="email"
               name="email"
               className="form-control mt-1"
               placeholder="Enter email"
               value={formData.email}
               onChange={handleChange}
               required
             />
           </div>
           <div className="form-group mt-3">
             <label>Password</label>
             <input
               type="password"
               name="password"
               className="form-control mt-1"
               placeholder="Enter password"
               value={formData.password}
               onChange={handleChange}
               required
             />
           </div>
           <p className="text-white mt-2">
           <a href="#">Forgot password?</a>
           </p>
           <div className="d-grid gap-2 mt-3">
             <button type="submit" className="btn btn-color-login">
               LOGIN
             </button>
           </div>
           
           {/* <div className="text-center">
             Not registered?{" "}
             <Link className="login-link-color" to="/register">
               Sign Up
             </Link>
           </div> */}
         </div>
       </form>
       <Modal show={showPopup} onHide={handleClose}>
         <Modal.Header closeButton>
           <Modal.Title>Error</Modal.Title>
         </Modal.Header>
         <Modal.Body>{errorMessage}</Modal.Body>
       </Modal>
      </Tab>
    </Tabs>
    </div>
    </div>    
    </>
  );
};

export default Login;
