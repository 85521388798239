import React from 'react'
import { Main } from '../Components/AllDraftComp/Main'

export const AllDraft = () => {
  return (
    <>
    <Main/>
    </>
  )
}
