import React from 'react'
import { Main } from '../Components/SubscriptionComp/Main'

export const Subscription = () => {
  return (
    <>
    <Main />
    </>
  )
}
