import React from 'react'
import { Banner } from '../Components/SupportingVeterans/Banner'
import { SecondBanner } from '../Components/SupportingVeterans/SecondBanner'
import { Brands } from '../Components/SupportingVeterans/Brands'

export const Supporting = () => {
  return (
    <>
    <Banner/>
    <SecondBanner/>
    <Brands/>
    </>
  )
}
