import axios from "axios";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { MdHome } from "react-icons/md";
import { PiBuildingsFill } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export const Main = () => {
  const [step, setStep] = useState(1);
  const [count, setCount] = useState(0); // update coffee bages
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(14);
  const [selectedRoastType, setSelectedRoastType] = useState([]); // New state
  const [selectedBagsQuantity, setSelectedBagsQuantity] = useState(0); // New state
  const [data, setData] = useState(0); // New state
  const [showFooter, setShowFooter] = useState(false); // component fixed
  const [selectedProductVariations, setSelectedProductVariations] = useState(
    []
  );
  const [selectedOrderSummry, setSelectedOrderSummry] = useState([]);
  const [productVariations, setProductVariations] = useState([]);
  const [productSummary, setProductSummary] = useState([]);
  const [selectedPackageId, setSelectedPackageId] = useState(null);

  const handleTimePeriodSelect = (days) => {
    setSelectedTimePeriod(days);
  };

  const [quantities, setQuantities] = useState({});

  // Use useEffect to initialize the quantities state based on the selected product variations
  useEffect(() => {
    // Check if selectedProductVariations is not empty
    if (selectedProductVariations.length > 0) {
      // Create a new object to store the quantities
      const initialQuantities = {};
      // Loop through selectedProductVariations and set the initial quantity for each variation
      selectedProductVariations.forEach((variation) => {
        // Use the variation name as the key and set the initial quantity to 0
        initialQuantities[variation.variation] = 0;
      });
      // Set the initial quantities state
      setQuantities(initialQuantities);
    }
  }, [selectedProductVariations]); // This effect will re-run whenever selectedProductVariations changes

  const incNum = (coffeeType) => {
    if (quantities[coffeeType] < 10) {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [coffeeType]: prevQuantities[coffeeType] + 1,
      }));
      console.log(coffeeType);
    }
  };

  const decNum = (coffeeType) => {
    if (quantities[coffeeType] > 0) {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [coffeeType]: prevQuantities[coffeeType] - 1,
      }));
    }
  };
  console.log("selected Product Variation", selectedProductVariations);
  const [selectedProductStep2, setSelectedProductStep2] = useState("");
  const getOrderSummary = async () => {
    try {
      const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk";
      const response = await axios.get(
        `https://ecom.testingwebsitelink.com/api/subscriptionOrder/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSelectedOrderSummry(response.data.data);
      setProductVariations(response.data.data.product_variations);
      setProductSummary(response.data.data.product);
      console.log("product_summary", response.data.data.product);
      console.log("product_variations", response.data.data.product_variations);
      console.log("setSelectedOrderSummry", response.data.data);
    } catch (error) {
      console.error("Error fetching product variations:", error);
      toast.error("Error fetching product variations");
    }
  };
  // Modify your handleStepChange function to include posting the selected variations and quantities to the API
  const handleStepChange = async (nextStep) => {
    try {
      // Construct the array of variation objects
      const variationsArray = selectedProductVariations.map((variation) => ({
        variation_id: variation.id,
        quantity: quantities[variation.variation],
      }));
      console.log("variations", variationsArray);
      // Post the array to the API
      const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk";
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/subscription",
        {
          user_id: userId,
          id: sessionId,
          product_id: selectedProductStep2.id,
          variations: variationsArray, // Include the array of variations
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Subscription added successfully:", response.data);
      // toast.success("Subscription added successfully");
      // Proceed to the next step
      setStep(nextStep);
      getOrderSummary();
    } catch (error) {
      console.error("Error adding subscription:", error);
      toast.error("Error adding subscription");
      // Handle error, maybe show a popup
    }
  };

  const handleProductSelectStep1 = async (product) => {
    try {
      setSelectedProducts(product); // Set selected product
      const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk"; // Replace with your API token
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/subscription",
        {
          user_id: userId,
          type: product,
          id: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      sessionStorage.setItem("sessionid", response.data.data.id);
      console.log("Subscription added successfully:", response.data);
      // toast.success("Subscription added successfully");
      // Proceed to the next step
      setStep(step + 1);
    } catch (error) {
      console.error("Error adding subscription:", error);
      toast.error("Error adding subscription");
      // Handle error, maybe show a popup
    }
  };
  const navigation = useNavigate();
  const handleDraft = async () => {
    try {
      const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk"; // Replace with your API token
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/updateSubscriptionStatus",
        {
          id: sessionId,
          status: "DRAFT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Subscription added successfully:", response.data);
      toast.success("Subscription added successfully");
      // Proceed to the next step
      navigation(`/payment/${sessionId}`);
    } catch (error) {
      console.error("Error adding subscription:", error);
      toast.error("Error adding subscription");
      // Handle error, maybe show a popup
    }
  };

  console.log(selectedProducts);
  const handleProductSelectStepImg = (product) => {
    setSelectedProductStep2(product);
    setStep(step + 1);
  };
  const fetchProductVariations = async (productId) => {
    try {
      const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk";
      const response = await axios.get(
        `https://ecom.testingwebsitelink.com/api/getproduct/${productId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSelectedProductVariations(response.data.data.product_variations);
    } catch (error) {
      console.error("Error fetching product variations:", error);
      toast.error("Error fetching product variations");
    }
  };
  const sessionId = sessionStorage.getItem("sessionid");
  const handleProductSelectStep2 = async (product) => {
    try {
      setSelectedProductStep2(product); // Set selected product
      const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk"; // Replace with your API token
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/subscription",
        {
          user_id: userId,
          id: sessionId,
          product_id: product.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Subscription added successfully:", response.data);
      // toast.success("Subscription added successfully");
      // Proceed to the next step
      // setStep(step + 1);
      fetchProductVariations(product.id);
    } catch (error) {
      console.error("Error adding subscription:", error);
      toast.error("Error adding subscription");
      // Handle error, maybe show a popup
    }
  };
  const handleTimePeriodSelectSummary = async (packageId) => {
    setSelectedPackageId(packageId);
    try {
      const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk"; // Replace with your API token
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/subscription",
        {
          user_id: userId,
          id: sessionId,
          package_id: packageId, // Ensure this is the correct value
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Subscription added successfully:", response.data);
      // toast.success("Subscription added successfully");
      // Proceed to the next step
      // setStep(step + 1);
    } catch (error) {
      console.error("Error adding subscription:", error);
      toast.error("Error adding subscription");
      // Handle error, maybe show a popup
    }
  };
  // console.log(selectedProducts);
  console.log(
    quantities["Mexican Chiapas"],
    quantities["Ethiopian Sidamo"],
    quantities["Ethiopian Sidamo"]
  );
  const handleRoastTypeSelect = (roastType) => {
    setSelectedRoastType((prevSelected) => {
      // Create a Set from the previous selected roast types
      const uniqueRoastTypesSet = new Set(prevSelected);
      // Add the new roast type to the Set
      uniqueRoastTypesSet.add(roastType);
      // Convert the Set back to an array and update the state
      return Array.from(uniqueRoastTypesSet);
    });
  };
  const keysWithZeroValue = Object.keys(data).filter((key) => data[key] === 0);
  const printSelectedRoastType = () => {
    const uniqueRoastTypes = Array.from(new Set(selectedRoastType));
    return selectedRoastType.join("");
  };

  console.log(printSelectedRoastType());
  console.log(selectedRoastType);
  const handleBagsQuantityChange = (quantity) => {
    setSelectedBagsQuantity(quantity); // Update selected bags quantity
  };
  const handlePackageSlect1 = (product) => {
    let value = "5%";
    if (step > 2) {
      value = product;
    }
    return value;
  };
  const handlePackageSlect2 = (quantity) => {
    let value = "5%";
    if (step > 2 && quantity > 0) {
      value = "You get 5% Off";
    }
    return value;
  };
  console.log(handlePackageSlect2(count));
  const handlePackageSlect3 = (quantity) => {
    let value = "10%";
    if (step > 2 && quantity > 1) {
      value = "You get 10% Off";
    }
    return value;
  };
  const handlePackageSlect4 = (quantity) => {
    let value = "20%";
    if (step > 2 && quantity > 2) {
      value = "You get 20% Off";
    }
    return value;
  };
  if (selectedBagsQuantity) {
  }
  const handleCoffeeSlect = () => {
    let text1 = "Select Options";
    if (step === 2) {
      return "Free Shipping";
    } else if (step === 3) {
      return "Free Shipping";
    } else if (step === 4) {
      return "Free Shipping";
    } else {
      return text1;
    }
  };
  console.log("handlelllll", handleCoffeeSlect());

  const [products, setProducts] = useState([]);
  const [packages, setPackages] = useState([]);
  console.log("data", products);
  const userId = sessionStorage.getItem("userId");
  // const handleBuySubscription = async () => {
  //   try {
  //     const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk";
  //     const response = await axios.post(
  //       "https://ecom.testingwebsitelink.com/api/subscription",
  //       {
  //         user_id: userId,
  //         type: selectedProducts,
  //         id: 0
  //         // quantity: num,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     toast.success("Add to Cart Successfully", response.data);
  //     // Redirect to the cart page or show a success message
  //   } catch (error) {
  //     toast.error("Error adding to cart:", error);
  //     // Handle error, maybe show a popup
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get token from local storage
        const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";

        // Make API request with token
        const response = await axios.get(
          "https://ecom.testingwebsitelink.com/api/getallproducts",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("databymmm", response.data);
        // Set the fetched data to the state
        setProducts(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchPackages = async () => {
      try {
        // Get token from local storage
        const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";

        // Make API request with token
        const response = await axios.get(
          "https://ecom.testingwebsitelink.com/api/packages",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Packages", response.data.data);
        // Set the fetched data to the state
        setPackages(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function
    fetchData();
    fetchPackages();
    // getOrderSummary();
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      {/* <section>
        <div className="container subscriptionSubs">
          <div className="row">
            <div className="col-md-12 subscriptionSubsCol">
              <img
                width={20}
                src={require("../assets/images/coffeeicon.png")}
                alt="subscription"
              />
              <h2>Subscription</h2>
            </div>
          </div>
        </div>
        <div className="container subscriptionBanner">
          <div className="row">
            <div className="col-md-12 subscriptionBannerCol">
              <h2>
                Get the best coffee at <br />
                your doorstep
              </h2>
            </div>
          </div>
        </div>
      </section> */}
      {step === 1 && (
        <section className="step-1">
          <div className="container subscriptionStep1">
            <div className="row">
              <div className="col-md-12 subscriptionStep1Col">
                <h2>Step 1</h2>
                <p>How many People | are your order for?</p>
              </div>
            </div>
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-lg-3 col-md-2 col-0"></div>
              {/* Map through your products and display each one */}
              {["Home", "Office"].map((product, index) => (
                <div
                  className="col-lg-3 col-md-4 col-6 subscriptionStep1Product"
                  key={index}
                >
                  <div
                    className={`subscriptionStep1ProductCol ${
                      selectedProducts === product ? "selected" : ""
                    }`}
                    onClick={() => handleProductSelectStep1(product)}
                  >
                    {product === "Home" ? (
                      <MdHome style={{ color: "white" }} size={100} />
                    ) : (
                      <PiBuildingsFill style={{ color: "white" }} size={100} />
                    )}
                    <div>
                      <h3>{product}</h3>
                      <p>{product === "Home" ? "1-5 People" : "6 + People"}</p>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-lg-3 col-0"></div>
            </div>
          </div>
        </section>
      )}
      {step === 2 && (
        <section className="step-2">
          <div className="container subscriptionStep1">
            <div className="row">
              <div className="col-md-12 subscriptionStep1Col">
                <h2>Step 2</h2>
                <p>Select your texture</p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-0 col-0"></div>
              {/* Map through your products and display each one */}
              {/* {products.map((product, index) => (
                <div
                  className="col-6 col-lg-3 subscriptionStep1Product"
                  key={index}
                >
                  <div
                    className={`subscriptionStep1ProductCol ${
                      selectedProductStep2 === product.title ? "selected" : ""
                    }`}
                    onClick={() => handleProductSelectStep2(product.title)}
                  >
                    <img
                      src={product.feature_image}
                      alt="product"
                      width={150}
                    />
                    <div>
                      <h3>{product.title}</h3>
                    </div>
                  </div>
                </div>
              ))} */}
              {products.map((product, index) => (
                <div
                  className="col-6 col-lg-3 subscriptionStep1Product"
                  key={index}
                >
                  <div
                    className={`subscriptionStep1ProductCol step2 ${
                      selectedProductStep2 === product.title ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleProductSelectStep2(product);
                      handleProductSelectStepImg(product.feature_image);
                    }}
                    // onClick={() => handleProductSelectStep2(product)}
                  >
                    <img
                      src={product.feature_image}
                      alt="product"
                      width={150}
                    />
                    <div>
                      <h3>{product.title}</h3>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-lg-0 col-0"></div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <button
                  className="backButton"
                  onClick={() => handleStepChange(1)}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
      {step === 3 && (
        <section className="step-3">
          <div className="container subscriptionStep1">
            <div className="row">
              <div className="col-md-12 subscriptionStep1Col">
                <h2>Step 3</h2>
                <p>Select Coffee Type</p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-0 col-12"></div>
              {/* Map through your coffee types and display each one */}
              {selectedProductVariations.map((coffeeType, index) => (
                <div
                  className="col-6 col-lg-4 col-md-4 subscriptionStep3Product"
                  key={index}
                >
                  <div
                    className="subscriptionStep3ProductCol"
                    onClick={() => handleRoastTypeSelect(coffeeType.variation)}
                  >
                    <div className="img-box">
                      {/* Display coffee type information */}
                      <img src={coffeeType.image} alt="product" width={250} />
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <h3>{coffeeType.variation}</h3>
                      <p>( 12 oz ) ${coffeeType.price} each</p>
                    </div>
                    {/* Quantity counter */}
                    <div className="price">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <button
                            className="btn"
                            type="button"
                            onClick={() => {
                              decNum(coffeeType.variation); // Corrected: Use coffeeType.variation here
                              setCount(count - 1);
                            }}
                          >
                            -
                          </button>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value={quantities[coffeeType.variation]} // Corrected: Use quantities[coffeeType.variation] here
                          readOnly
                        />
                        <div className="input-group-prepend">
                          <button
                            className="btn"
                            type="button"
                            onClick={() => {
                              setCount(count + 1);
                              incNum(coffeeType.variation); // Corrected: Use coffeeType.variation here
                              handleBagsQuantityChange(
                                quantities[coffeeType.variation] + 1
                              );
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-md-0 col-12"></div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <div
                  className="d-flex gap-2"
                  style={{ justifyContent: "center" }}
                >
                  <button
                    className="backButton"
                    onClick={() => handleStepChange(2)}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {step === 4 && (
        <div className="container subscriptionStep4">
          <div className="row">
            <div className="col-md-12 subscriptionStep1Col">
              <h2>Step 4</h2>
              <p>Checkout</p>
            </div>
            <div className="col-md-12 productBoxDescription">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <h2
                        style={{
                          textAlign: "left",
                          color: "white",
                          fontSize: "32px",
                        }}
                      >
                        Select Delivery Frequency
                      </h2>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    {packages.map((days, index) => (
                      <div
                        className="col-lg-4 col-4 subscriptionStep1Product"
                        key={index}
                      >
                        <div
                          className={`subscriptionStep1ProductCol ${
                            selectedTimePeriod === days.number_of_days
                              ? "selected"
                              : ""
                          }`}
                          onClick={() => {
                            handleTimePeriodSelect(days.number_of_days);
                            handleTimePeriodSelectSummary(days.id);
                          }}
                        >
                          <h5>Ships every </h5>
                          <div>
                            <h3>
                              {days.number_of_days} <br />
                              <span>days</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="productBoxDescription">
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <h2
                          style={{
                            textAlign: "left",
                            color: "white",
                            fontSize: "32px",
                          }}
                        >
                          Subscription Summary
                        </h2>
                      </div>
                    </div>
                    {/* Display selected product details */}
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Coffee Texture</h4>
                      </div>
                      <div className="col">
                        <h5>{productSummary.title}</h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Roast Type</h4>
                      </div>
                      <div className="col">
                        <ul>
                          {productVariations.map((roastType, index) => (
                            <li key={index}>{roastType.variation} </li>
                          ))}
                        </ul>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Coffee Bags</h4>
                      </div>
                      <div className="col">
                        <h5>{selectedOrderSummry.total_quantity}bags</h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Shipping Frequency</h4>
                      </div>
                      <div className="col">
                        <h5>{selectedTimePeriod} Days</h5>
                      </div>
                    </Row>
                    {/* Add more rows for other selected details */}
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Coffee bags Price</h4>
                      </div>
                      <div className="col">
                        <h5>$ {selectedOrderSummry.total}</h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Shipping cost</h4>
                      </div>
                      <div className="col">
                        <h5>$ {selectedOrderSummry.shipping_amount}</h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Discount</h4>
                      </div>
                      <div className="col">
                        <h5> -$ {selectedOrderSummry.discount_amount}</h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4 className="fnlDetails">SUBTOTAL</h4>
                      </div>
                      <div className="col">
                        <h5 className="fnlDetails">
                          $ {selectedOrderSummry.final_amount}
                        </h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct mt-4">
                      <div className="col">
                        <Link onClick={() => handleDraft()}>
                          <button
                            className={`backButton w-100 ${
                              !selectedPackageId ? "disabled" : ""
                            }`}
                            disabled={!selectedPackageId}
                          >
                            Confirm & Pay
                          </button>
                        </Link>
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <button
                className="backButton"
                onClick={() => handleStepChange(3)}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="container packageContainerMain">
        <Row
          className=" row rounded border-warning  align-content-center col-lg-10 col packageContainerMargin "
          style={{ margin: "auto" }}
        >
          <div className="row col-lg-6 col-12 packageBoxesDiv">
            <div
              className="col-2 justify-content-center align-content-center packageBoxes "
              style={
                handleCoffeeSlect() === "Free Shipping"
                  ? { backgroundColor: "#48412D" }
                  : { backgroundColor: "#1d1d1d" }
              }
            >
              <div>
                <div className="coffee-slect-text">
                  {/* {step === 3 && (
                    <img
                      src={require(`../assets/images/${selectedProductStep2}.png`)}
                      alt="product"
                      width={50}
                    />
                  )} */}
                  {handleCoffeeSlect()}
                </div>
                {/* <div>{handlePackageSlect1(selectedProductStep2)}</div> */}
              </div>
            </div>
            <div
              className="col-2 justify-content-center align-content-center packageBoxes coffee-slect-text"
              style={
                handlePackageSlect2(count) === "You get 5% Off"
                  ? { backgroundColor: "#48412D" }
                  : {}
              }
            >
              {handlePackageSlect2(count)}
            </div>
            <div
              className="col-2 justify-content-center align-content-center packageBoxes coffee-slect-text"
              style={
                handlePackageSlect3(count) === "You get 10% Off"
                  ? { backgroundColor: "#48412D" }
                  : {}
              }
            >
              {handlePackageSlect3(count)}
            </div>
            <div
              className="col-2 justify-content-center align-content-center packageBoxes coffee-slect-text"
              style={
                handlePackageSlect4(count) === "You get 20% Off"
                  ? { backgroundColor: "#48412D" }
                  : {}
              }
            >
              {handlePackageSlect4(count)}
            </div>
          </div>
          {/* </div> */}
          <div className="col-lg-3 col-8 ">
          {step === 4 ? (
            <p className="text-warning packageShipmentText">shipment: ${selectedOrderSummry.shipping_amount}</p>
          ) : (
                      ""
                    )}
          </div>
          <div className="col-lg-2 col-4 packageButtonDiv1">
            <div className="row packageButtonDiv">
              <div className="col-md-12">
                {count === 0 ? (
                  <button
                    className="step4Button"
                    onClick={() => handleStepChange(step)}
                  >
                    <p>Step {step}</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                    </svg>
                  </button>
                ) : (
                  <>
                    {step === 4 ? (
                      ""
                    ) : (
                      <>
                        <button
                          className="step4Button"
                          // disabled
                          onClick={() => handleStepChange(4)}
                        >
                          <p>Step 4</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                          </svg>
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};
