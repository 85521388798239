import React from 'react'

export const Banner = () => {
  return (
    <>
    <section className="main coffeemain">
    <div className="container coffeeBanner my-4">
        <div className="row">
            <div className="col-md-12 coffeeBennercol">
            <h1>Support our <br/>Veterans  </h1>
            </div>
        </div>
    </div>
    </section>
    </>
  )
}
