import React from 'react'
import { Banner } from '../Components/Aboutus/Banner'
import { SecondBanner } from '../Components/Aboutus/SecondBanner'

export const Aboutus = () => {
  return (
    <>
    <Banner/>
    <SecondBanner/>
    </>
  )
}
