import React, { useEffect, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHome, faBook, faCog, faSignOutAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import './SideMenu.css'; // Import your CSS file for styling
import { Link, useNavigate } from 'react-router-dom';
import { Form, Nav, Spinner, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { MdOutlineCancel } from 'react-icons/md';
import { FcCancel } from 'react-icons/fc';
import { GiCancel } from 'react-icons/gi';
import { FaArrowCircleRight } from 'react-icons/fa';

const SideMenu = ({ isOpen, toggleMenu, cartItems }) => {
    const [cartProducts, setCartProducts] = useState([]);
  const [totallAmount, setTotallAmount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigate();

  
  useEffect(() => {
    setIsLoading(false);
  }, [cartItems]);
 
  useEffect(() => {
    const userId = sessionStorage.getItem("userId") || sessionStorage.getItem("randomUserId");
    const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";

    if (!userId || !token) {
      console.error("User ID or token not found");
      return;
    }

    const fetchCartProducts = async () => {
      try {
        const response = await axios.get(
          `https://ecom.testingwebsitelink.com/api/getcart?user_id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setCartProducts(response.data.products || []); // Ensure it's an array
        setTotallAmount(response.data);
        setIsLoading(false);

        console.log("Cart Products:", response.data.products);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching cart products:", error);
      }
    };

    fetchCartProducts();
  }, []);


  
  return (
    <div className={`side-menu ${isOpen ? 'open' : ''}`}>
      <div className='p-3 text-left'>
        <div className='res-flex'>
        <div className='cancelButton'>
        <GiCancel className='cancel' onClick={() => toggleMenu()}/>
            </div>
            <Link to="/"><div>
      <img width={200} src={require("../assets/logo/logo.png")} alt="Google" />
      </div>
      </Link>
        </div>
      {/* <p className='mt-2 text-gray text-sm text-left paraSidemenu'>
      Sed ut perspiciatis unde omnis iste natus error voluptatem accan tium doloremque laudantium totam rem aperiam
      </p> */}
      </div>
      <div class="sidebar">
            <div class="sidebar_widget recent_widgets">
                {/* <div style={{display:'flex',justifyContent:'space-between'}}> */}
                {/* <button className="proceedCheckout3">Clear Cart</button> */}
                {/* </div> */}
                <ul>
                    {/* {cartItems.length > 0 ? (
          cartItems.map((item, index) => (
            <div key={index} className="cart-item">
              <img src={item.image} alt={item.variation} />
              <div className="cart-item-details">
                <h4>{item.variation}</h4>
                <p>Quantity: {item.quantity}</p>
                <p>Total Price: ${item.variation_total_price}</p>
              </div>
            </div>
          ))
        ) : (
          <p>Your cart is empty</p>
        )} */}
                {cartItems.length > 0 ? (
          cartItems.map((item, index) => (
              <div className="cartItem" key={index}>
                <div style={{ display: "flex", width: "100%", alignItems: "center",justifyContent:'center' }}>
                  <div className="productInfo">
                    {/* {product.product_images && product.product_images.length > 0 && ( */}
                      <img
                        className="productImage"
                        src={item.feature_image}
                        alt="Product"
                      />
                    {/* )} */}
                    <div className="productDetails">
                      <h5>{item.title}</h5>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <Table id="myTable" className="table">
                        <tbody>
                          {/* {product.product_variations && product.product_variations.length > 0 && product.product_variations.map(
                            (variation, index) => ( */}
                              <tr>
                                <td>
                                  <div className="product-img">
                                    <div className="img-prdct">
                                      <img src={item.image} alt={item.feature_image} />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {item.variation}
                                </td>
                                <td>
                                  <div className="button-container">
                                  {/* <button
                                                                    className="cart-qty-minus"
                                                                    type="button"
                                                                    value="-"
                                                                    onClick={() => decNum(index, variation)}
                                                                >
                                                                    -
                                                                </button> */}
                                                                <input
                                                                    type="text"
                                                                    name="qty"
                                                                    min="1"
                                                                    readOnly
                                                                    className="qty form-control"
                                                                    value={item.quantity}
                                                                    // onChange={(e) => handleChange(e, variation)}
                                                                />
                                                                {/* <button
                                                                    className="cart-qty-plus"
                                                                    type="button"
                                                                    value="+"
                                                                    onClick={() => incNum(index, variation)}
                                                                >
                                                                    +
                                                                </button> */}
                                                                
                                  </div>
                                </td>
                                <td align="right" style={{ display: "flex" }}>
                                  ${" "}
                                  <span id="amount" className="amount">
                                    {item.variation_total_price}
                                  </span>
                                </td>
                                {/* <td>
                                  <MdOutlineCancel
                                    onClick={() => handleCartRemove(variation)}
                                    className="cancel"
                                  />
                                </td> */}
                              </tr>
                            {/* )
                          )} */}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
           ))
        ) : (
          <p className='text-white'>Your cart is empty</p>
        )}
                </ul>
                <Link to='/checkout'><h5 className="widget_title">View Cart <FaArrowCircleRight/></h5></Link>

            </div>
            
        </div>
      
    </div>
  );
};

export default SideMenu;
