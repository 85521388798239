import React from 'react'
import { Banner } from '../Components/ContactUs/Banner'
import { Main } from '../Components/ContactUs/Main'

export const Contactus = () => {
  return (
    <>
    <Banner/>
    <Main/>
    </>
  )
}
