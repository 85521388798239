import React from 'react'

export const Banner = () => {
  return (
    <>
    <div className="container banner-hero my-4">
        <div className="row">
            <div className="col-md-12 bennercol">
            <h1>Time to discover <span>First <br/>Call coffee Company</span></h1>
            </div>
            <div className="col-md-12 social-icon">
                {/* <img width={15} src={require("../assets/images/facebook.png")} alt="banner" />
                <img width={25} src={require("../assets/images/linkedin.png")} alt="banner" />
                <img width={25} src={require("../assets/images/twitter.png")} alt="banner" /> */}
            </div>
        </div>
    </div>
    </>
  )
}
