import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Button } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { FaRegUserCircle, FaSearch, FaShoppingCart } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import { RiDraftLine } from 'react-icons/ri';
import { VscGitPullRequestDraft } from 'react-icons/vsc';

export const Topbar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Check if user is logged in
  const isLoggedIn = sessionStorage.getItem("userId") !== null;
  const randomId = sessionStorage.getItem("randomUserId");
  const userId = sessionStorage.getItem("userId");

  const handleLogout = () => {
    // Clear all session data
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("randomUserId");
    // Redirect to home page or any other desired route
    window.location.href = "/";
  };
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <>
      <Navbar collapseOnSelect expand="lg">
        <Container className='topbarContainer'>
          <Navbar.Brand>
            <NavLink to="/"><img width={210} src={require("../assets/logo/logo.png")} alt="Google" /></NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" style={{ justifyContent: "space-around" }}>
            <Nav className='menunav'>
              {(() => {
                if (randomId) {
                  return <NavLink to="/login" className="nav-link sub" >Subscription</NavLink>;
                } else if(isLoggedIn) {
                  return <NavLink to="/subscription" className={currentPath === "subscription" ? "nav-link active" : "nav-link"}>Subscription</NavLink>;
                } else{
                  return <Link to="/login" className="nav-link sub">Subscription</Link>;
                }
              })()}
              {/* <Modal className='popupMsg' show={show} onHide={handleClose}>
                <Modal.Header className='bg-danger text-white' closeButton>
                  <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>You have to login for Buy Subscription!</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Link to="/login">
                    <Button className='btn btn-color-login border-0 text-black' variant="primary" onClick={handleClose}>
                      Login
                    </Button>
                  </Link>
                </Modal.Footer>
              </Modal> */}
              <NavLink to="/about-us" className={currentPath === "about-us" ? "nav-link active" : "nav-link"}>About Us</NavLink>
              <NavLink to="/supporting-veterans" className={currentPath === "supporting-veterans" ? "nav-link active" : "nav-link"}>Supporting Veterans</NavLink>
              <NavLink to="/contact-us" className={currentPath === "contact-us" ? "nav-link active" : "nav-link"}>Contact Us</NavLink>
            </Nav>
            <Nav className='secondMenu'>
              <div className='menuicons'>
                {/* <FaSearch /> */}
                <Link to='/checkout'><FaShoppingCart /></Link>
                {isLoggedIn ? (
                <>
                <Link to={`/all-draft/${userId}`}><VscGitPullRequestDraft/></Link>
                  <Button variant="link" className="text-white nav-link header-icon" onClick={handleLogout}>
                    Logout
                  </Button>
                </>
                ) : (
                  <NavLink to="/login" className="nav-link header-icon" activeClassName="active">
                    <FaRegUserCircle />
                  </NavLink>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
