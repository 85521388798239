import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

export const Main = () => {
  const [products, setProducts] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [activeProducts, setActiveProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [activeProductsData, setActiveProductsData] = useState([]);
  console.log("data", products);
  const userId = sessionStorage.getItem("userId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get token from local storage
        const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";

        // Make API request with token
        const response = await axios.get(
          `https://ecom.testingwebsitelink.com/api/getSubscriptionOrderList?user_id=${userId}&status=DRAFT`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("databymmm", response.data);
        // Set the fetched data to the state
        setProducts(response.data.data);
        setProductsData(response.data.data.product);
        setIsLoading(false);

      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);

      }
    };
    const activeOrderData = async () => {
      try {
        // Get token from local storage
        const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";

        // Make API request with token
        const response = await axios.get(
          `https://ecom.testingwebsitelink.com/api/getSubscriptionOrderList?user_id=${userId}&status=Active`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("databymmm", response.data);
        // Set the fetched data to the state
        setActiveProducts(response.data.data);
        setActiveProductsData(response.data.data.product);
        setIsLoading(false);

      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);

      }
    };

    // Call the fetchData function
    fetchData();
    activeOrderData();
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <section className="special-online-shop">
        <div className="container SpecialOnlineShop">
          <div className="row align-items-center">
            <div className="col-md-9 col-6 subtitle">
              {/* <p>SPECIAL ONLINE SHOP</p> */}
              <h2>
                <span>Your Subscriptions</span>
              </h2>
            </div>
            {/* <div className="col-md-3 col-6 view-all">
              <a href="#">See All Products</a>
            </div> */}
          </div>
          <div className="text-center">
          <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="profile" title="Active">
      <div className="row products">
      {isLoading ? ( 
        <div className="loader-container text-center">
          <Spinner animation="border" role="status">
          </Spinner>
        </div>
      ) : (
        <>
        {activeProducts.length === 0 ? (
            <div className="text-white pt-4">No Active Products Available</div>
          ) : (
            <>
            {activeProducts.map((item, index) => (
              <div className="col-md-4 col-6" key={index}>
                
                  <div className="special-online-shop-box">
                  <h4 className="activeStatus">{item.status}</h4>
                    <div className="img-box">
                    <img src={`https://ecom.testingwebsitelink.com/public/${item.product.feature_image}`} alt="special-online-shop" />
                    </div>
                    <div className="subtitleBox">
                      <h4>{item.product.title}</h4>
                      {/* <h4>{item.product.title}</h4>
                      <h4>{item.product.title}</h4>
                      <h4>{item.product.title}</h4> */}
                      <HiOutlineArrowNarrowRight />

                    </div>
                    <div class="table-responsive">
                    <table id="myTable" class="table">
                    <tbody>
                    {item.product_variations && item.product_variations.length > 0 && item.product_variations.map((variation, index) => (

<tr key={index}>
    <td>
        <div class="product-img">
            <div class="img-prdct"><img src={`https://ecom.testingwebsitelink.com/public/${variation.image}`} /></div>
        </div>
    </td>
    <td>
        <p>{variation.variation}</p>
    </td>
    <td>
        <p>$ {variation.price}</p>
    </td>
    {/* <td>
        <input type="text" value="72" class="price form-control" disabled />
    </td> */}
</tr>
))}
</tbody>
</table>
<div className="totall">
<h5>Totall Amount</h5>
<h5>$ {item.final_amount}</h5>
</div>
<Link to={`/draft/${item.id}`}>
<div className="py-3 text-center d-flex" style={{justifyContent:'center'}}>
<button className="step4Button">View Order</button>
</div>
</Link>
</div>
                  </div>
              </div>
            ))}
            </>
          )}
          </>
      )}
          </div>
      </Tab>
      <Tab eventKey="home" title="Draft">
      <div className="row products">
      {isLoading ? ( 
        <div className="loader-container text-center">
          <Spinner animation="border" role="status">
          </Spinner>
        </div>
      ) : (
        <>
        {products.length === 0 ? (
            <div className="text-white pt-4">No Draft Products Available</div>
          ) : (
            <>
            {products.map((item, index) => (
              <div className="col-md-4 col-6" key={index}>
                
                  <div className="special-online-shop-box">
                  <h4 className="status">{item.status}</h4>
                    <img src={`https://ecom.testingwebsitelink.com/public/${item.product.feature_image}`} alt="special-online-shop" />
                    <div className="subtitleBox">
                      <h4>{item.product.title}</h4>
                      {/* <h4>{item.product.title}</h4>
                      <h4>{item.product.title}</h4>
                      <h4>{item.product.title}</h4> */}
                      <HiOutlineArrowNarrowRight />

                    </div>
                    <div class="table-responsive">
                    <table id="myTable" class="table">
                    <tbody>
                    {item.product_variations && item.product_variations.length > 0 && item.product_variations.map((variation, index) => (

<tr key={index}>
    <td>
        <div class="product-img">
            <div class="img-prdct"><img src={`https://ecom.testingwebsitelink.com/public/${variation.image}`} /></div>
        </div>
    </td>
    <td>
        <p>{variation.variation}</p>
    </td>
    <td>
        <p>$ {variation.price}</p>
    </td>
    {/* <td>
        <input type="text" value="72" class="price form-control" disabled />
    </td> */}
</tr>
))}
</tbody>
</table>
<div className="totall">
<h5>Totall Amount</h5>
<h5>$ {item.final_amount}</h5>
</div>
<Link to={`/draft/${item.id}`}>
<div className="py-3 text-center d-flex" style={{justifyContent:'center'}}>
<button className="step4Button">View Order</button>
</div>
</Link>
</div>
                  </div>
              </div>
            ))}
            </>
          )}
          </>
      )}
          </div>
      
      </Tab>
      
    </Tabs>
            </div>
          
        </div>
      </section>
    </>
  );
};
