import axios from "axios";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { MdHome } from "react-icons/md";
import { PiBuildingsFill } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export const Main = () => {
  const [step, setStep] = useState(1);
  const [count, setCount] = useState(0); // update coffee bages
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(14);
  const [selectedRoastType, setSelectedRoastType] = useState([]); // New state
  const [selectedBagsQuantity, setSelectedBagsQuantity] = useState(0); // New state
  const [data, setData] = useState(0); // New state
  const [showFooter, setShowFooter] = useState(false); // component fixed
  const [selectedProductVariations, setSelectedProductVariations] = useState([]);
  const [selectedOrderSummry, setSelectedOrderSummry] = useState([]);
  const [productVariations, setProductVariations] = useState([]);
  const [productSummary, setProductSummary] = useState([]);
  const [packageSummary, setPackageSummary] = useState([]);

  const handleTimePeriodSelect = (days) => {
    setSelectedTimePeriod(days);
  };

  const [quantities, setQuantities] = useState({});

  // Use useEffect to initialize the quantities state based on the selected product variations
  useEffect(() => {
    // Check if selectedProductVariations is not empty
    if (selectedProductVariations.length > 0) {
      // Create a new object to store the quantities
      const initialQuantities = {};
      // Loop through selectedProductVariations and set the initial quantity for each variation
      selectedProductVariations.forEach((variation) => {
        // Use the variation name as the key and set the initial quantity to 0
        initialQuantities[variation.variation] = 0;
      });
      // Set the initial quantities state
      setQuantities(initialQuantities);
    }
  }, [selectedProductVariations]); // This effect will re-run whenever selectedProductVariations changes
  
  const incNum = (coffeeType) => {
    if (quantities[coffeeType] < 10) {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [coffeeType]: prevQuantities[coffeeType] + 1,
      }));
      console.log(coffeeType);
    }
  };

  const decNum = (coffeeType) => {
    if (quantities[coffeeType] > 0) {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [coffeeType]: prevQuantities[coffeeType] - 1,
      }));
    }
  };
console.log('selected Product Variation', selectedProductVariations)
  const [selectedProductStep2, setSelectedProductStep2] = useState("");
 
  // Modify your handleStepChange function to include posting the selected variations and quantities to the API
const handleStepChange = async (nextStep) => {
  try {
    // Construct the array of variation objects
    const variationsArray = selectedProductVariations.map((variation) => ({
      variation_id: variation.id,
      quantity: quantities[variation.variation],
    }));
    console.log('variations',variationsArray)
    // Post the array to the API
    const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk";
    const response = await axios.post(
      "https://ecom.testingwebsitelink.com/api/subscription",
      {
        user_id: userId,
        id: sessionId,
        product_id: selectedProductStep2.id,
        variations: variationsArray, // Include the array of variations
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("Subscription added successfully:", response.data);
    toast.success("Subscription added successfully");
    // Proceed to the next step
    setStep(nextStep);
    // getOrderSummary();
  } catch (error) {
    console.error("Error adding subscription:", error);
    toast.error("Error adding subscription");
    // Handle error, maybe show a popup
  }
};


  const handleProductSelectStep1 = async (product) => {
    try {
      setSelectedProducts(product); // Set selected product
      const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk"; // Replace with your API token
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/subscription",
        {
          user_id: userId,
          type: product,
          id: 0
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      sessionStorage.setItem("sessionid", response.data.data.id);
      console.log("Subscription added successfully:", response.data);
      toast.success("Subscription added successfully");
      // Proceed to the next step
      setStep(step + 1);
    } catch (error) {
      console.error("Error adding subscription:", error);
      toast.error("Error adding subscription");
      // Handle error, maybe show a popup
    }
  };
  
console.log(selectedProducts);
const handleProductSelectStepImg = (product) => {
  setSelectedProductStep2(product);
  setStep(step + 1);
};
const fetchProductVariations = async (productId) => {
  try {
    const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk";
    const response = await axios.get(
      `https://ecom.testingwebsitelink.com/api/getproduct/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setSelectedProductVariations(response.data.data.product_variations);
  } catch (error) {
    console.error("Error fetching product variations:", error);
    toast.error("Error fetching product variations");
  }
};
const sessionId = sessionStorage.getItem("sessionid")
const handleProductSelectStep2 = async (product) => {
  try {
    setSelectedProductStep2(product); // Set selected product
      const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk"; // Replace with your API token
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/subscription",
        {
          user_id: userId,
          id: sessionId,
          product_id: product.id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Subscription added successfully:", response.data);
      toast.success("Subscription added successfully");
      // Proceed to the next step
      // setStep(step + 1);
      fetchProductVariations(product.id);
    } catch (error) {
      console.error("Error adding subscription:", error);
      toast.error("Error adding subscription");
      // Handle error, maybe show a popup
    }
  };
  const handleTimePeriodSelectSummary = async (packageId) => {
    try {
      const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk"; // Replace with your API token
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/subscription",
        {
          user_id: userId,
          id: sessionId,
          package_id: packageId,  // Ensure this is the correct value
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Subscription added successfully:", response.data);
      toast.success("Subscription added successfully");
      // Proceed to the next step
      // setStep(step + 1);
    } catch (error) {
      console.error("Error adding subscription:", error);
      toast.error("Error adding subscription");
      // Handle error, maybe show a popup
    }
  };
  // console.log(selectedProducts);
  console.log(
    quantities["Mexican Chiapas"],
    quantities["Ethiopian Sidamo"],
    quantities["Ethiopian Sidamo"]
  );
  const handleRoastTypeSelect = (roastType) => {
    setSelectedRoastType((prevSelected) => {
      // Create a Set from the previous selected roast types
      const uniqueRoastTypesSet = new Set(prevSelected);
      // Add the new roast type to the Set
      uniqueRoastTypesSet.add(roastType);
      // Convert the Set back to an array and update the state
      return Array.from(uniqueRoastTypesSet);
    });
  };
  const keysWithZeroValue = Object.keys(data).filter((key) => data[key] === 0);
  const printSelectedRoastType = () => {
    const uniqueRoastTypes = Array.from(new Set(selectedRoastType));
    return selectedRoastType.join("");
  };

  console.log(printSelectedRoastType());
  console.log(selectedRoastType);
  const handleBagsQuantityChange = (quantity) => {
    setSelectedBagsQuantity(quantity); // Update selected bags quantity
  };
  const handlePackageSlect1 = (product) => {
    let value = "5%";
    if (step > 2) {
      value = product;
    }
    return value;
  };
  const handlePackageSlect2 = (quantity) => {
    let value = "5%";
    if (step > 2 && quantity > 0) {
      value = "You get 5% Off";
    }
    return value;
  };
  console.log(handlePackageSlect2(count));
  const handlePackageSlect3 = (quantity) => {
    let value = "10%";
    if (step > 2 && quantity > 1) {
      value = "You get 10% Off";
    }
    return value;
  };
  const handlePackageSlect4 = (quantity) => {
    let value = "20%";
    if (step > 2 && quantity > 2) {
      value = "You get 20% Off";
    }
    return value;
  };
  if (selectedBagsQuantity) {
  }
  const handleCoffeeSlect = () => {
    let text1 = "Slect your coffee type"
    if (step === 3) {
     return <img
      src={`${selectedProductStep2}`}
      alt="product"
      width={50}
    />
    } else {
      return text1
    }
  }
  console.log("handlelllll",handleCoffeeSlect());

  const [products, setProducts] = useState([]);
  const [packages, setPackages] = useState([]);
  console.log("data", products);
  const userId = sessionStorage.getItem("userId");
  // const handleBuySubscription = async () => {
  //   try {
  //     const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk";
  //     const response = await axios.post(
  //       "https://ecom.testingwebsitelink.com/api/subscription",
  //       {
  //         user_id: userId,
  //         type: selectedProducts,
  //         id: 0
  //         // quantity: num,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     toast.success("Add to Cart Successfully", response.data);
  //     // Redirect to the cart page or show a success message
  //   } catch (error) {
  //     toast.error("Error adding to cart:", error);
  //     // Handle error, maybe show a popup
  //   }
  // };
  const navigation = useNavigate()
  const handleDraft = async (event) => {
    event.preventDefault(); // Prevent default action of the link
    const { pathname } = window.location;
    const id = pathname.split("/").pop();
  
    // Navigate to payment page
    navigation(`/payment/${id}`);
  };
  const handleCancel = async (event) => {
    event.preventDefault(); // Prevent default action of the link
    const { pathname } = window.location;
    const id = pathname.split("/").pop();
  
    try {
      const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk"; // Replace with your API token
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/cancel-subscription",
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Subscription added successfully:", response.data);
      toast.success("Subscription added successfully");
      // Proceed to the next step
      setProducts(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get token from local storage
        const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";

        // Make API request with token
        const response = await axios.get(
          "https://ecom.testingwebsitelink.com/api/getallproducts",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("databymmm", response.data);
        // Set the fetched data to the state
        setProducts(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchPackages = async () => {
      try {
        // Get token from local storage
        const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";

        // Make API request with token
        const response = await axios.get(
          "https://ecom.testingwebsitelink.com/api/packages",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Packages", response.data.data);
        // Set the fetched data to the state
        setPackages(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const { pathname } = window.location;
    const id = pathname.split("/").pop();
    const getOrderSummary = async () => {
      try {
        const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk";
        const response = await axios.get(
          `https://ecom.testingwebsitelink.com/api/subscriptionOrder/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedOrderSummry(response.data.data);
        setProductVariations(response.data.data.product_variations);
        setProductSummary(response.data.data.product);
        setPackageSummary(response.data.data.package);
        console.log('product_summary',response.data.data.product);
        console.log('product_variations',response.data.data.product_variations);
        console.log('setSelectedOrderSummry',response.data.data);
      } catch (error) {
        console.error("Error fetching product variations:", error);
        toast.error("Error fetching product variations");
      }
    };
    
    getOrderSummary();
    fetchData();
    fetchPackages();
    // getOrderSummary();
  }, []); // Empty dependency array ensures this effect runs only once
 
  
  return (
    <>
      {/* <section>
        <div className="container subscriptionSubs">
          <div className="row">
            <div className="col-md-12 subscriptionSubsCol">
              <img
                width={20}
                src={require("../assets/images/coffeeicon.png")}
                alt="subscription"
              />
              <h2>Subscription</h2>
            </div>
          </div>
        </div>
        <div className="container subscriptionBanner">
          <div className="row">
            <div className="col-md-12 subscriptionBannerCol">
              <h2>
                Get the best coffee at <br />
                your doorstep
              </h2>
            </div>
          </div>
        </div>
      </section> */}
      {/* {step === 1 && ( */}
        <section className="step-1 pt-4">
          <div className="container subscriptionStep1">
            <div className="row">
              <div className="col-md-12 subscriptionStep1Col">
                <h2>Step 1</h2>
                <p>How many People your are order for</p>
              </div>
            </div>
            <div className="row" style={{ alignItems: "center" ,justifyContent:'center'}}>
              <div className="col-lg-3 col-md-2 col-0"></div>
                <div
                  className="col-lg-3 col-md-4 col-6 subscriptionStep1Product"
                >
                  <div
                    className="subscriptionStep1ProductCol selected"
                  >
                    {selectedOrderSummry.type === "Home" ? (
                      <MdHome style={{ color: "white" }} size={100} />
                    ) : (
                      <PiBuildingsFill style={{ color: "white" }} size={100} />
                    )}
                    <div>
                      <h3>{selectedOrderSummry.type}</h3>
                      <p>
                        {selectedOrderSummry.type === "Home" ? "1-5 Peoples" : "5-100 Peoples"}
                      </p>
                    </div>
                  </div>
                </div>
              <div className="col-lg-3 col-0"></div>
            </div>
          </div>
        </section>
       {/* {step === 2 && ( */}
        <section className="step-2 pt-4">
          <div className="container subscriptionStep1">
            <div className="row">
              <div className="col-md-12 subscriptionStep1Col">
                <h2>Step 2</h2>
                <p>Select your textures</p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-0 col-0"></div>
             
  <div
    className="col-6 col-lg-3 subscriptionStep1Product"
    // key={index}
  >
    <div
      className="subscriptionStep1ProductCol selected"
    >
      <img
        src={`https://ecom.testingwebsitelink.com/public/${productSummary.feature_image}`}
        alt="product"
        width={150}
      />
      <div>
        <h3>{productSummary.title}</h3>
      </div>
    </div>
  </div>

              <div className="col-lg-0 col-0"></div>
            </div>
          </div>
        </section>
      {/* {step === 3 && ( */}
        <section className="step-3 pt-4">
          <div className="container subscriptionStep1">
            <div className="row">
              <div className="col-md-12 subscriptionStep1Col">
                <h2>Step 3</h2>
                <p>Select The Coffee Type</p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-0 col-12"></div>
              {/* Map through your coffee types and display each one */}
                           {selectedOrderSummry.product_variations && selectedOrderSummry.product_variations.length > 0 && selectedOrderSummry.product_variations.map((variation, index) => (
  <div
    className="col-6 col-lg-4 col-md-4 subscriptionStep3Product"
    key={index}
  >
    <div className="subscriptionStep3ProductCol">
      {/* Display coffee type information */}
      <div className="img-box">
      <img
        src={`https://ecom.testingwebsitelink.com/public/${variation.image}`}
        alt="product"
        width={250}
      />
      </div>
      <div style={{ textAlign: "left" }}>
        <h3>{variation.variation}</h3>
        <p>( 12 oz ) ${variation.price} each</p>
      </div>
      {/* Quantity counter */}
      <div className="price">
        <div className="input-group">
          <div className="input-group-prepend">
            <button
              className="btn"
              disabled
            >
              -
            </button>
          </div>
          <input
            type="text"
            className="form-control"
            value={variation.quantity}
            readOnly
          />
          <div className="input-group-prepend">
            <button
              className="btn"
              type="button"
              disabled
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
))}

              <div className="col-md-0 col-12"></div>
            </div>
          </div>
        </section>
      {/* {step === 4 && ( */}
        <div className="container subscriptionStep4 pt-4">
          <div className="row">
            <div className="col-md-12 subscriptionStep1Col">
              <h2>Step 4</h2>
              <p>Confirm & Checkout</p>
            </div>
            <div className="col-md-12 productBoxDescription">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <h2
                        style={{
                          textAlign: "left",
                          color: "white",
                          fontSize: "32px",
                        }}
                      >
                        Select Your Delivery Frequency
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {/* {packages.map((days, index) => ( */}
                      <div
                        className="col-lg-4 col-6 subscriptionStep1Product"
                        // key={index}
                      >
                        <div
                          className="subscriptionStep1ProductCol selected"
                        >
                          <h5>Ships every {packageSummary.number_of_days} days</h5>
                          <div>
                            <h3>
                              {packageSummary.number_of_days} <br />
                              <span>days</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="productBoxDescription">
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <h2
                          style={{
                            textAlign: "left",
                            color: "white",
                            fontSize: "32px",
                          }}
                        >
                          Subscription Total
                        </h2>
                      </div>
                    </div>
                    {/* Display selected product details */}
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Coffee type</h4>
                      </div>
                      <div className="col">
                        <h5>{productSummary.title}</h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Rost Type</h4>
                      </div>
                      <div className="col">
                        <ul>
                          {productVariations.map((roastType, index) => (
                            <li key={index}>{roastType.variation} - {roastType.quantity} Bags</li>
                          ))}
                        </ul>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Coffee Bags</h4>
                      </div>
                      <div className="col">
                        <h5>{selectedOrderSummry.total_quantity}bags</h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Shipping Frequency</h4>
                      </div>
                      <div className="col">
                        <h5>{packageSummary.number_of_days} Days</h5>
                      </div>
                    </Row>
                    {/* Add more rows for other selected details */}
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Coffee bags Price</h4>
                      </div>
                      <div className="col">
                        <h5>$ {selectedOrderSummry.total}</h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Shipping cost</h4>
                      </div>
                      <div className="col">
                        <h5>$ {selectedOrderSummry.shipping_amount}</h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Discount</h4>
                      </div>
                      <div className="col">
                        <h5> -$ {selectedOrderSummry.discount_amount}</h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4 className="fnlDetails">Order Total</h4>
                      </div>
                      <div className="col">
                        <h5 className="fnlDetails">$ {selectedOrderSummry.final_amount}</h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct mt-4">
                      <div className="col">
                      {/* <Link to="#" onClick={handleDraft}>
    <button className="backButton w-100">
      Confirm & Pay
    </button>
  </Link> */}
  {selectedOrderSummry.status === "Active" ? (
<>
<Link to="#" onClick={handleCancel}>
    <button className="backButton w-100">
      Cancel Order
    </button>
  </Link>

</>
  ) : (
    <>
    <Link to="#" onClick={handleDraft}>
    <button className="backButton w-100">
      Confirm & Pay
    </button>
  </Link>
    </>
  )}
    
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};
